.component {
    display: flex;
    justify-content: space-between;
}

p{
    font-size: 16px;
}

.price{
    font-weight: bold;
}

.title{
    font-weight: bold;
}