body {
    margin: 0;
}

.App {
    width: 100vw;
    height: 100vh;
    background: url('./assets/background.jpg') no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

header {
    display: flex;
    justify-content: center;
    align-items: center;
}

header h1 {
    color: #000;
    background-color: #FFF;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.container {
    width: 60%;
    height: 60%;
    border-radius: 15px;
    background-color: #FFF;
    display: flex;
    justify-content: space-evenly;
    padding: 20px;
    flex-direction: column;
}

.menu {
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: #FFF;
    border-radius: 10px;
    margin-bottom: 10px;
}

.menu h3 {
    color: #000;
    cursor: pointer;
}